<script setup>
import {onMounted, onUnmounted, ref, computed} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useStore} from 'vuex'

const route = useRoute()
const router = useRouter()
const store = useStore()
const product = ref(null)

// Check if item is in cart
const isInCart = computed(() => {
  return store.state.cart.some(item => item.id === product.value?.id)
})

onMounted(() => {
  const productId = route.params.id
  product.value = store.state.products.find(p => p.id === parseInt(productId))

  if (!product.value) {
    router.push("/catalog")
  }

  // Initialize Telegram WebApp back button
  if (window.Telegram?.WebApp) {
    window.Telegram.WebApp.BackButton.show()
    window.Telegram.WebApp.BackButton.onClick(() => {
      router.push("/catalog")
    })

    // Initialize Telegram WebApp main button
    window.Telegram.WebApp.MainButton.show()
    window.Telegram.WebApp.MainButton.onClick(() => {
      if (isInCart.value) {
        router.push('/cart')  // Navigate to cart if already in cart
      } else {
        addToCart(product.value)  // Add product to cart
      }
    })

    // Set the button text dynamically
    window.Telegram.WebApp.MainButton.text = isInCart.value
        ? "Перейти в корзину"
        : "Добавить в корзину"
  }
})

onUnmounted(() => {
  if (window.Telegram?.WebApp) {
    window.Telegram.WebApp.BackButton.hide()
    window.Telegram.WebApp.MainButton.hide()
  }
})

const addToCart = (product) => {
  store.dispatch('addToCart', product)
  window.Telegram.WebApp.MainButton.text = "Перейти в корзину"
}

const goToCart = () => {
  router.push('/cart')  // Navigate to cart
}

const goToBack = () => {
  router.go(-1);
}
</script>
<template>
  <div v-if="product" class="mini-apps_centre">
    <div class="more_inf" style="display: flex">
      <p>Услуга</p>
      <button @click="goToBack" class="cross2">
        <img src="/static/img/cross.svg">
      </button>

    </div>
    <div class="description_view">
      <div class="img_fake">
        <div class="circle1"></div>
        <img class="heart" style="min-width: 71px; max-width: 71px;" :src="product.image" :alt="product.name">
        <div class="circle2"></div>
      </div>
      <div class="content_desc">
        <p>{{ product.name }}</p>
        <del v-if="product.old_price" class="old-price__cart-item">{{ product.old_price }} ₽</del>
        <p class="new__price">{{ product.price }} ₽</p>
        <button
            :class="['add_in_cart', { 'in-cart': isInCart }]"
            @click="isInCart ? goToCart() : addToCart(product)"
        >
          {{ isInCart ? 'Перейти в корзину' : 'Добавить в корзину' }}
        </button>
      </div>
    </div>
    <p class="mini_p">Описание</p>
    <p class="back-mini_p" style="white-space: pre-line;">{{ product.description }}</p>

  </div>
</template>

<style scoped>
.more_inf{
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.cross2 {
    padding: 7px;
    margin-left: auto;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
}

.add_in_cart {
  margin-top: 1vh;
  min-width: 34px;
  padding: 8px 12px;
  gap: 6px;
  border-radius: 20px;
  border: 0;
  background-color: #43D5CA;
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add_in_cart.in-cart, .great_but.in-cart {
  background-color: #707579;
}

.content_desc {
  margin-left: 15%;
  margin-top: 1vh;
}

.new__price {
  font-size: 15px;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: 0.15px;
  text-align: left;
  color: #43D5CA;
}

.description_view {
  background-color: #FFFFFF;
  border-radius: 10px;
  display: flex;
}

.heart {
  margin-left: 20%;
}

.circle1 {
  background-color: #43D5CA;
  width: 40px;
  height: 40px;
  border-radius: 15px 3px 100px 3px;
}

.circle2 {
  background-color: #43D5CA;
  width: 40px;
  height: 40px;
  border-radius: 100px 0px 0px 0px;
  transform: translate(150%, 0);
}

.mini-apps_centre {
    margin-top: unset;
    width: 90vw;
    margin-left: 5vw;
    display: flex;
    flex-flow: column;
    justify-content: center;
    min-height: 100%;
    margin-bottom: 10rem;
}
.mini_p {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.08px;
  text-align: left;
  color: #707579;
  margin-left: 4%;
  margin-top: 3vh;
}

.back-mini_p {
  background-color: #ffffff;
  padding: 10px 16px 10px 13px;
  border-radius: 12px;
  margin-top: 1vh;
  transform: translate(-50%, 0);
  margin-left: 50%;
  width: 90vw;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.23px;
  text-align: left;
}

.great_but {
  min-width: 42px;
  padding: 12px 14px;
  gap: 8px;
  border-radius: 12px;
  background-color: #43D5CA;
  color: #FFFFFF;
  border: 0;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.23px;
  text-align: center;
  margin-top: 2vh;
  transform: translate(-50%, 0);
  margin-left: 50%;
  width: 90vw;
  cursor: pointer;
  transition: all 0.3s ease;
}
</style>