import {createStore} from 'vuex';
import auth from './auth';

export default createStore({
    modules: {
        auth
    },
    state: {
        selectedCategory: 0,
        products: [
            {
                "id": 1,
                "date": "до 24 октября",
                "category": 0,
                "name": "Консультация терапевта по телефону",
                "description": "• Консультация\n• Подбор терапии\n• Оформление заявки на выезд врача",
                "price": 0,
                "old_price": 1490,
                "image": "/static/img/Therapy.png"
            },
            {
                "id": 2,
                "date": "до 24 октября",
                "category": 0,
                "name": "Первичный прием терапевта",
                "description": "• Выезд терапевта\n• Осмотр\n• Постановка диагноза + капельницы\n• Разработка плана лечения/обследования",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 3,
                "date": "до 24 октября",
                "category": 0,
                "name": "Онлайн консультация терапевта",
                "description": "• Аудио-видео консультация\n• Корректировка плана лечения\n• Подбор терапии",
                "price": 3000,
                "image": "/static/img/Phone.svg"
            },
            {
                "id": 4,
                "date": "до 24 октября",
                "category": 1,
                "name": "Консультация педиатра по телефону",
                "description": "• Консультация\n• Подбор терапии\n• Оформление заявки на выезд врача",
                "price": 0,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 5,
                "date": "до 24 октября",
                "category": 1,
                "name": "Первичный прием педиатра",
                "description": "• Выезд педиатра\n• Осмотр\n• Постановка диагноза + капельницы\n• Разработка плана лечения/обследования",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 6,
                "date": "до 24 октября",
                "category": 1,
                "name": "Онлайн консультация педиатра",
                "description": "• Аудио-видео консультация\n• Корректировка плана лечения\n• Подбор терапии",
                "price": 3000,
                "image": "/static/img/Phone.svg"
            },
            {
                "id": 7,
                "date": "до 24 октября",
                "category": 2,
                "name": "Консультация невролога по телефону",
                "description": "• Консультация\n• Подбор терапии\n• Оформление заявки на выезд врача",
                "price": 0,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 8,
                "date": "до 24 октября",
                "category": 2,
                "name": "Первичный прием невролога",
                "description": "• Выезд невролога\n• Осмотр\n• Постановка диагноза + капельницы\n• Разработка плана лечения/обследования",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 9,
                "date": "до 24 октября",
                "category": 2,
                "name": "Онлайн консультация невролога",
                "description": "• Аудио-видео консультация\n• Корректировка плана лечения\n• Подбор терапии",
                "price": 3000,
                "image": "/static/img/Phone.svg"
            },
            {
                "id": 10,
                "date": "до 24 октября",
                "category": 3,
                "name": "Консультация кардиолога по телефону",
                "description": "• Консультация\n• Подбор терапии\n• Оформление заявки на выезд врача",
                "price": 0,
                "image": "/static/img/heart.png"
            },
            {
                "id": 11,
                "date": "до 24 октября",
                "category": 3,
                "name": "Первичный прием кардиолога",
                "description": "• Выезд кардиолога\n• Осмотр\n• Постановка диагноза + капельницы\n• Разработка плана лечения/обследования",
                "price": 4990,
                "image": "/static/img/heart2.png"
            },
            {
                "id": 12,
                "date": "до 24 октября",
                "category": 3,
                "name": "Онлайн консультация кардиолога",
                "description": "• Аудио-видео консультация\n• Корректировка плана лечения\n• Подбор терапии",
                "price": 3000,
                "image": "/static/img/Phone.svg"
            },
            {
                "id": 13,
                "date": "до 24 октября",
                "category": 4,
                "name": "Консультация инфекциониста по телефону",
                "description": "• Консультация\n• Подбор терапии\n• Оформление заявки на выезд врача",
                "price": 0,
                "image": "/static/img/virus.png"
            },
            {
                "id": 14,
                "date": "до 24 октября",
                "category": 4,
                "name": "Первичный прием инфекциониста",
                "description": "• Выезд инфекциониста\n• Осмотр\n• Постановка диагноза + капельницы\n• Разработка плана лечения/обследования",
                "price": 4990,
                "image": "/static/img/virus.png"
            },
            {
                "id": 15,
                "date": "до 24 октября",
                "category": 4,
                "name": "Онлайн консультация инфекциониста",
                "description": "• Аудио-видео консультация\n• Корректировка плана лечения\n• Подбор терапии",
                "price": 3000,
                "image": "/static/img/Phone.svg"
            },
            {
                "id": 16,
                "date": "до 24 октября",
                "category": 5,
                "name": "Консультация хирурга по телефону",
                "description": "• Консультация\n• Подбор терапии\n• Оформление заявки на выезд врача",
                "price": 0,
                "image": "/static/img/hirurg.png"
            },
            {
                "id": 17,
                "date": "до 24 октября",
                "category": 5,
                "name": "Первичный прием хирурга",
                "description": "• Выезд хирурга\n• Осмотр\n• Постановка диагноза + капельницы\n• Разработка плана лечения/обследования",
                "price": 4990,
                "image": "/static/img/hirurg.png"
            },
            {
                "id": 18,
                "date": "до 24 октября",
                "category": 5,
                "name": "Онлайн консультация хирурга",
                "description": "• Аудио-видео консультация\n• Корректировка плана лечения\n• Подбор терапии",
                "price": 3000,
                "image": "/static/img/Phone.svg"
            },
            {
                "id": 19,
                "date": "до 24 октября",
                "category": 6,
                "name": "Консультация онколога по телефону",
                "description": "• Консультация\n• Подбор терапии\n• Оформление заявки на выезд врача",
                "price": 0,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 20,
                "date": "до 24 октября",
                "category": 6,
                "name": "Первичный прием онколога",
                "description": "• Выезд онколога\n• Осмотр\n• Постановка диагноза + капельницы\n• Разработка плана лечения/обследования",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 21,
                "date": "до 24 октября",
                "category": 6,
                "name": "Онлайн консультация онколога",
                "description": "• Аудио-видео консультация\n• Корректировка плана лечения\n• Подбор терапии",
                "price": 3000,
                "image": "/static/img/Phone.svg"
            },
            {
                "id": 22,
                "date": "до 24 октября",
                "category": 7,
                "name": "Консультация нарколога по телефону",
                "description": "• Консультация\n• Подбор терапии\n• Оформление заявки на выезд врача",
                "price": 0,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 23,
                "date": "до 24 октября",
                "category": 7,
                "name": "Первичный прием нарколога",
                "description": "• Выезд нарколога\n• Осмотр\n• Постановка диагноза + капельницы\n• Разработка плана лечения/обследования",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 24,
                "date": "до 24 октября",
                "category": 7,
                "name": "Онлайн консультация нарколога",
                "description": "• Аудио-видео консультация\n• Корректировка плана лечения\n• Подбор терапии",
                "price": 3000,
                "image": "/static/img/Phone.svg"
            },
            {
                "id": 25,
                "date": "до 24 октября",
                "category": 8,
                "name": "Консультация уролога по телефону",
                "description": "• Консультация\n• Подбор терапии\n• Оформление заявки на выезд врача",
                "price": 0,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 26,
                "date": "до 24 октября",
                "category": 8,
                "name": "Первичный прием уролога",
                "description": "• Выезд уролога\n• Осмотр\n• Постановка диагноза + капельницы\n• Разработка плана лечения/обследования",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 27,
                "date": "до 24 октября",
                "category": 8,
                "name": "Онлайн консультация уролога",
                "description": "• Аудио-видео консультация\n• Корректировка плана лечения\n• Подбор терапии",
                "price": 3000,
                "image": "/static/img/Phone.svg"
            },
            {
                "id": 28,
                "date": "до 24 октября",
                "category": 9,
                "name": "Внутривенный укол",
                "description": "• Выезд на дом\n• Постановка лекарства по назначению врача",
                "price": 2450,
                "image": "/static/img/med-drop.png"
            },
            {
                "id": 29,
                "date": "до 24 октября",
                "category": 9,
                "name": "Внутримышечный укол",
                "description": "• Выезд на дом\n• Постановка лекарства по назначению врача",
                "price": 2450,
                "image": "/static/img/med-drop.png"
            },
            {
                "id": 30,
                "date": "до 24 октября",
                "category": 9,
                "name": "Подкожный укол",
                "description": "• Выезд на дом\n• Постановка лекарства по назначению врача",
                "price": 2450,
                "image": "/static/img/med-drop.png"
            },
            {
                "id": 31,
                "date": "до 24 октября",
                "category": 10,
                "name": "Капельница при ОРВИ и гриппе",
                "description": "• Выезд врача + осмотр\n• Консультация\n• Подбор терапии и обследовании\n• Мгновенное улучшение состояния\n• Восполнение дефицита организма минералами и витаминами\n• Очищение организма от токсинов",
                "price": 2450,
                "image": "/static/img/med-drop.png"
            },
            {
                "id": 32,
                "date": "до 24 октября",
                "category": 10,
                "name": "Капельница при кишечных инфекциях",
                "description": "• Консультация\n• Подбор терапии\n• Облегчает течение ОРЗ и снимает симптомы\n• Насыщает организм витаминами\n• Снимает воспалительные процессы с верхних дыхательных путей",
                "price": 4990,
                "image": "/static/img/med-drop.png"
            },
            {
                "id": 33,
                "date": "до 24 октября",
                "category": 10,
                "name": "Реабилитация онкопациентам",
                "description": "• Выезд врача + осмотр\n• Консультация\n• Подбор терапии и обследование\n• Мгновенное улучшение состояния после химиотерапии\n• Восполнение дефицита организма минералами и витаминами\n• Очищение организма от токсинов \n• Снятие тревожности и восстановление нервной системы\n• Нормализация сна",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 34,
                "date": "до 24 октября",
                "category": 10,
                "name": "Реабилитация после инфаркта и инсульта",
                "description": "• Выезд врача + осмотр\n• Консультация\n• Подбор терапии и обследование\n• Восполнение дефицита организма минералами и витаминами\n• Восстановление гемодинамики и трофика сосудов\n• Восполнение транспортировки и насыщения крови кислородом\n• Снятие тревожности и восстановления нервной системы",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 35,
                "date": "до 24 октября",
                "category": 11,
                "name": "Снятие хирургических швов и скоб",
                "description": "• Выезд на дом\n• Осмотр и обработка раны\n• Снятие швов/скоб\n• Перевязка раны",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 36,
                "date": "до 24 октября",
                "category": 11,
                "name": "Установка назогастрального зонда",
                "description": "• Выезд на дом\n• Консультация по питанию\n• Осмотр и обработка\n• Установка зонда",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 37,
                "date": "до 24 октября",
                "category": 11,
                "name": "Установка трахеостом",
                "description": "• Выезд на дом\n• Консультация по сохранности\n• Осмотр и обработка\n• Перевязка\n• Установка трахеостомы",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 38,
                "date": "до 24 октября",
                "category": 11,
                "name": "Промывка ПОРТ-системы",
                "description": "• Выезд на дом\n• Промывка Гепарином\n• Осмотр обработка\n• Перевязка",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 39,
                "date": "до 24 октября",
                "category": 11,
                "name": "Установка катетера Фолея",
                "description": "• Выезд на дом\n• Осмотр и обработка\n• Консультация по сохранности\n• Перевязка",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 40,
                "date": "до 24 октября",
                "category": 11,
                "name": "Установка катетера в цистоме",
                "description": "• Выезд на дом\n• Осмотр и обработка\n• Консультация по сохранности\n• Перевязка",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 41,
                "date": "до 24 октября",
                "category": 12,
                "name": "Установка периферического катетера",
                "description": "• Выезд специалиста на дом\n• Осмотр и обработка\n• Установка катетера в вену\n• Перевязка и фиксация катетера\n• Консультация по сохранности",
                "price": 2450,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 42,
                "date": "до 24 октября",
                "category": 12,
                "name": "Перевязка хирургическая",
                "description": "• Выезд специалиста на дом\n• Консультация по уходу\n• Осмотр и обработка\n• Снятие болевого синдрома\n• Фиксация и перевязка стерильными расходными материалами",
                "price": 2450,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 43,
                "date": "до 24 октября",
                "category": 12,
                "name": "Обработка трофических язв и пролежней",
                "description": "• Выезда специалиста на дом\n• Консультация по уходу\n• Осмотр и обработка\n• Снятие болевого синдрома\n• Фиксация и перевязка стерильными расходными материалами",
                "price": 2450,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 45,
                "date": "до 24 октября",
                "category": 12,
                "name": "Установка капельниц",
                "description": "• Капельница при ОРВИ и Гриппе\n• Капельница при кишечных инфекциях\n• Реабилитация онкопациентам\n• Реабилитация после инфаркта и инсульта",
                "price": 2450,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 46,
                "date": "до 24 октября",
                "category": 12,
                "name": "Уколы",
                "description": "• Внутривенный укол\n• Внутримышечный укол\n• Подкожный укол",
                "price": 2450,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 47,
                "date": "до 24 октября",
                "category": 13,
                "name": "Капельница от похмелья",
                "description": "• Выезд врача-нарколога\n• Диагностика и мониторинг физических показателей\n• От 2-3 капельниц\n• Дезинтоксикационная терапия\n• Психологическая помощь",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 48,
                "date": "до 24 октября",
                "category": 13,
                "name": "Капельница от запоя",
                "description": "• Выезд врача-нарколога\n• Диагностика и мониторинг физических показателей\n• Промывка желудка\n• Детоксикация крови от остатков алкоголя\n• Нормализация работы внутренних органов\n• Психологическая помощь семье",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 49,
                "date": "до 24 октября",
                "category": 14,
                "name": "Капельница \"Детокс\"",
                "description": "• Облегчает последствия интоксикации организма\n• Восстанавливает функции печени\n• Очищает организм от токсинов\n• Подавляет воспалительные процессы и улучшает цвет лица\n• Снимает симптомы аллергии\n• Мгновенно улучшает самочувствие: уходят бессонница и боль",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 50,
                "date": "до 24 октября",
                "category": 14,
                "name": "Капельница \"АлкоSTOP\"",
                "description": "• Восстанавливает функции печени\n• Снимает симптомы аллергии\n• Подавляет воспалительные процессы и улучшает цвет лица\n• Облегчает последствия интоксикации организма\n• Мгновенно улучшает самочувствие: уходят бессонница и боль\n• Очищает организм от токсинов",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 51,
                "date": "до 24 октября",
                "category": 14,
                "name": "Капельница \"Иммунитет\"",
                "description": "• Повышает иммунитет\n• Улучшает защитные функции организма\n• Облегчает последствия гриппа и ОРВИ\n• Подходит для профилактики сезонной аллергии\n• Насыщает организм витаминами и микроэлементами\n• Снимает воспаление и улучшает цвет лица",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 52,
                "date": "до 24 октября",
                "category": 14,
                "name": "Капельница \"Антистресс\"",
                "description": "• Нормализация сна\n• Снятие тревожности\n• Расслабление нервной системы\n• Состояние спокойствия",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 53,
                "date": "до 24 октября",
                "category": 14,
                "name": "Капельница \"Золушка\"",
                "description": "• Устранение пигментации\n• Увлажнение кожи\n• Мгновенный омолаживающий эффект\n• Подтягивание кожи и замедление процессов старения\n• Улучшение качества волос и ногтей\n• Защита клеток от влияния окружающей и выведение токсинов\n• Активизация обменных процессов и улучшение циркуляции крови",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 54,
                "date": "до 24 октября",
                "category": 14,
                "name": "Капельница \"Спорт + Витамины\"",
                "description": "• Повышает выносливость\n• Организм быстро восстанавливается после заболеваний\n• Пробуждаются внутренние ресурсы организма\n• Повышается способность противостоять инфекциям и вирусам",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 55,
                "date": "до 24 октября",
                "category": 15,
                "name": "УЗИ на дому",
                "description": "• Выезд специалиста на дом\n• Консультация и подбор области исследования\n• Срочный выезд\n• Разработка плана лечения",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 56,
                "date": "до 24 октября",
                "category": 15,
                "name": "Анализы на дому",
                "description": "• Выезд медсестры на дом\n• Консультация\n• Срочный выезд\n• Взятие биоматериала",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 57,
                "date": "до 24 октября",
                "category": 15,
                "name": "Рентген на дому",
                "description": "• Выезд специалиста на дом\n• Консультация и подбор области исследования\n• Срочный выезд\n• Расшифровка снимков",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 58,
                "date": "до 24 октября",
                "category": 15,
                "name": "Перевозка пациентов",
                "description": "• Оценка транспортабельности пациента\n• Подготовка пациента\n• Спуск и подъем на этаж\n• Передаем пациента \"из рук в руки\"\n• Безопасная перевозка\n• Фиксированная стоимость",
                "price": 4990,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 59,
                "date": "до 24 октября",
                "category": 999,
                "name": "Консультация терапевта по телефону",
                "description": "• Консультация\n• Подбор терапии\n• Оформление заявки на выезд врача",
                "price": 0,
                "old_price": 1490,
                "image": "/static/img/Therapy.png"
            },
            {
                "id": 60,
                "date": "до 24 октября",
                "category": 999,
                "name": "Первичный прием терапевта",
                "description": "• Выезд терапевта\n• Осмотр\n• Постановка диагноза + капельницы\n• Разработка плана лечения/обследования",
                "price": 4990,
                "old_price": 6490,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 61,
                "date": "до 24 октября",
                "category": 999,
                "name": "Онлайн консультация терапевта",
                "description": "• Аудио-видео консультация\n• Корректировка плана лечения\n• Подбор терапии",
                "price": 3000,
                "old_price": 3490,
                "image": "/static/img/Phone.svg"
            },
            {
                "id": 62,
                "date": "до 24 октября",
                "category": 999,
                "name": "Первичный прием педиатра",
                "description": "• Выезд педиатра\n• Осмотр\n• Постановка диагноза + капельницы\n• Разработка плана лечения/обследования",
                "price": 4990,
                "old_price": 5490,
                "image": "/static/img/Hospital Room.png"
            },
            {
                "id": 63,
                "date": "до 24 октября",
                "category": 999,
                "name": "Онлайн консультация педиатра",
                "description": "• Аудио-видео консультация\n• Корректировка плана лечения\n• Подбор терапии",
                "price": 3000,
                "old_price": 3490,
                "image": "/static/img/Phone.svg"
            },
            {
                "id": 64,
                "date": "до 24 октября",
                "category": 999,
                "name": "Консультация невролога по телефону",
                "description": "• Консультация\n• Подбор терапии\n• Оформление заявки на выезд врача",
                "price": 0,
                "old_price": 1490,
                "image": "/static/img/Hospital Room.png"
            }
        ],

        cart: [],
        categories: [
            "Терапевт",
            "Педиатр",
            "Невролог",
            "Кардиолог",
            "Инфекционист",
            "Хирург",
            "Онколог",
            "Нарколог",
            "Уролог",
            "Уколы",
            "Установка капельниц",
            "Хирургия",
            "Вызов медсестры",
            "Вывод из запоя",
            "IV-Терапия",
            "Другие услуги"
        ],
        user: {
            name: '',
            phone: '',
            address: '',
            birthdate: ''
        },
        searchTerm: '',
        bonusPercentage: 5 // 5% bonus on all purchases
    },

    mutations: {
        ADD_TO_CART(state, item) {
            const existingItem = state.cart.find(i => i.id === item.id);
            if (!existingItem) {
                state.cart.push({...item, quantity: 1});
            }
        },

        REMOVE_FROM_CART(state, item) {
            const index = state.cart.findIndex(i => i.id === item.id);
            if (index !== -1) {
                state.cart.splice(index, 1);
            }
        },

        UPDATE_USER(state, userData) {
            state.user = {...state.user, ...userData};
        },

        SET_SELECTED_CATEGORY(state, categoryIndex) {
            state.selectedCategory = categoryIndex;
        },
        SET_SEARCH_TERM(state, term) {
            state.searchTerm = term;
        },
    },

    actions: {
        addToCart({commit}, item) {
            commit('ADD_TO_CART', item);
            console.log("item added to cart");
        },

        removeFromCart({commit}, item) {
            commit('REMOVE_FROM_CART', item);
        },
        updateUser({commit}, userData) {
            commit('UPDATE_USER', userData);
        },
        selectCategory({commit}, categoryIndex) {
            commit('SET_SELECTED_CATEGORY', categoryIndex);
        },
        setSearchTerm({commit}, term) {
            commit('SET_SEARCH_TERM', term);
        },
        async fetchUser({commit}, telegramData) {
            try {
                const response = await fetch('https://dedovagency.ru/backend/api/user/auth', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({telegramData})
                });

                if (response.ok) {
                    const userData = await response.json();
                    commit('UPDATE_USER', userData);
                    return userData;
                }
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        }
    },

    getters: {
        products: state => state.products,
        cart: state => state.cart,
        user: state => state.user,

        filteredProducts(state) {
            let products = state.products;

            products = products.filter(product => product.category === state.selectedCategory);

            if (state.searchTerm) {
                products = products.filter(product =>
                    product.name?.toLowerCase().includes(state.searchTerm.toLowerCase()) ||
                    product.description?.toLowerCase().includes(state.searchTerm.toLowerCase())
                );
            }


            return products;
        },


        filteredPromotions(state) {
            let products = state.products;

            products = products.filter(product => product.category === 999);


            return products;
        },

        getDiscountedPrice: () => (item) => {
            if (!item.discount) return item.price;

            if (item.discount.type === 'percentage') {
                return Math.round(item.price * (1 - item.discount.value / 100));
            } else if (item.discount.type === 'fixed') {
                return Math.max(0, item.price - item.discount.value);
            }
            return item.price;
        },

        cartItemsWithDiscounts: (state, getters) => {
            return state.cart.map(item => ({
                ...item,
                originalPrice: item.price,
                discountedPrice: getters.getDiscountedPrice(item),
                totalPrice: getters.getDiscountedPrice(item) * item.quantity
            }));
        },

        subtotal: state => {
            return state.cart.reduce((total, item) => total + (item.price * item.quantity), 0);
        },

        totalDiscounts: (state, getters) => {
            return state.cart.reduce((total, item) => {
                const discount = item.price - getters.getDiscountedPrice(item);
                return total + (discount * item.quantity);
            }, 0);
        },

        bonusAmount: (state, getters) => {
            const discountedTotal = getters.cartItemsWithDiscounts.reduce(
                (total, item) => total + item.totalPrice, 0
            );
            return Math.round(discountedTotal * (state.bonusPercentage / 100));
        },

        cartTotal: (state, getters) => {
            const discountedTotal = getters.cartItemsWithDiscounts.reduce(
                (total, item) => total + item.totalPrice, 0
            );
            return discountedTotal - getters.bonusAmount;
        }
    }
})
